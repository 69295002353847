import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { SliderTwoData } from "@/data";

const { subTitle, title, button } = SliderTwoData;
const SliderTwo = () => {
  return (
    <section className="hero" style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          
          maxHeight: 700,
          minHeight: 550
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        //aspectRatio={3 / 1}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src="../assets/images/hero.avif"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <Container>
          <Row>
            <Col lg={12} className="text-left">
              <h2 className="hero-subtext">{subTitle}</h2>
              <h1 className="hero-title">{title}</h1>
              <Link to={button.url} className={`common_btn red_bg`}>
                <span>{button.label}</span>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default SliderTwo;
