import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "@/components/layout";
import CallToActionOne from "@/components/call-to-action-one";
import Footer from "@/components/footer";
import ServiceHomeTwo from "@/components/service-home-two";
import BlogHome from "@/components/blog-home";
import Testimonials from "@/components/testimonials";
import MenuContextProvider from "@/context/menu-context";
import HeaderTwo from "@/components/header-two";
import SliderTwo from "@/components/slider-two";
import FeatureTwo from "@/components/feature-two";

const HomeTwo = ({data}) => {
  let { description } = data.site.siteMetadata;
  const title = `Talent Acquisition Consulting & Recruiting`;
  const posts = data.allMarkdownRemark.nodes.slice(0, 3);

  return (
    <MenuContextProvider>
        <Layout PageTitle={title}>
          <SEO title={title} description={description}/>
          <HeaderTwo />
          <SliderTwo />
          <CallToActionOne extraClassName="ready" />
          <ServiceHomeTwo />
          <FeatureTwo />
          <Testimonials />
          <BlogHome posts={posts}/>
          <Footer />
        </Layout>
    </MenuContextProvider>
  );
};

export default HomeTwo;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
  }
`
