import React from "react";

import Col  from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { ServiceHomeTwoData } from "@/data";
import SectionTitle from "./section-title";

const ServiceHomeTwo = () => {
  return (
    <section className="service_section_2 commonSection">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <SectionTitle data={ServiceHomeTwoData} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceHomeTwo;
